
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { getFullName } from "@/cms-services/helpers";
import { FolderDataSource } from "@/data/Tree/FolderDataSource";
@Component
export default class Home extends Vue {
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 10,
    },
    className: "order",
  });
  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 100,
    },
  });
  folderDataSource: FolderDataSource = new FolderDataSource({
    className: "OrderFolder",
    rootFolder: "mainFolder",
  });

  getUserFullName(item) {
    return `${item?.lastName} ${item?.firstName}`;
  }

  createItemModel: any = {
    userProfileId: null,
  };

  createItemActions = {
    getUserProfiles: () => {
      return this.userProfileDataSource.items;
    },
  };
  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Заказы",
        disabled: true,
      },
    ];
  }

  createItemFields: any = [
    {
      editor: "enum",
      attrs: {
        label: "Пользователь",
        outlined: true,
      },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
      validations: [
        {
          validator: "required",
          value: true,
          errorMessage: "Пожалуйста выберите пользователя",
        },
      ],
    },
  ];

  customHeaders: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Пользователь",
      value: "userProfile",
      sortable: true,
    },
    {
      text: "Сумма закза",
      value: "orderSum",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];
  loaded: boolean = false;

  async created() {
    await this.userProfileDataSource.get();
    this.loaded = true;
  }
}
